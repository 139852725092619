body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 0;
  padding-right: 5%;
  bottom: 10%;
  background-color: transparent;
}

.rs-sidenav-subtle {
  background-color: transparent !important;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-family: "NTR", sans-serif;
  letter-spacing: 0em;
  line-height: 1.6em;
  font-size: 16px;
  padding: 0.2em;
  font-weight: bold;
}

a {
  text-decoration: none !important;
  color: var(--green-bright) !important;
}

a:hover {
  color: var(--lightest-slate) !important;
}

.sidebar-logos {
  padding-top: 42px;
  width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
}

img {
  height: 18px;
}

@media only screen and (max-width: 600px) {
  .sidebar-nav {
    width: 100%;
    height: 50px;
  }

  .sidebar-links {
    padding-left: 20px;
    flex-direction: row;
    text-align: right;
    font-size: 9px;
  }

  .sidebar-logos {
    padding-top: 0px;
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 50%;
  }

  img {
    height: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .sidebar-nav {
    position: unset;
    align-items: center;
  }
  .sidebar-logos {
    width: 70px;
    padding-left: unset;
  }
}

#intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15%;
  padding-top: 5%;
  min-height: 100vh;
}

canvas {
  width: 200px !important;
}

.Typist {
  color: var(--lightest-slate);
  text-align: center;
  font-size: 76px;
  margin-top: -30px;
}
.Typist .Cursor--blinking {
  color: var(--green-bright);
  padding-left: 4px;
}

.intro-title {
  font-family: "NTR", sans-serif;
  font-size: 86px;
}
.intro-name {
  font-family: "NTR", sans-serif;
  color: var(--green-bright);
  font-weight: bold;
  font-size: 86px;
}

.intro-subtitle {
  margin-top: -36px;
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-weight: 300;
  font-size: 55px;
}

.intro-desc {
  color: var(--slate);
  font-family: "NTR", sans-serif;
  font-size: 20px;
  max-width: 55%;
  padding-bottom: 50px;
}

.intro-contact {
  font-size: 22px;
  font-family: "NTR", sans-serif;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid var(--green-bright);
  border-radius: 4px;
}

.intro-contact:hover {
  background-color: var(--lightest-navy);
}

b {
  font-size: 17px;
}

@media only screen and (max-width: 600px) {
  #gazi-model {
    display: none;
  }

  #intro {
    min-height: unset;
    padding-left: unset;
    margin: 0;
    align-items: center;
  }

  .intro-title {
    font-size: 46px;
  }
  .intro-name {
    font-size: 46px;
  }

  .Typist {
    font-size: 36px;
  }

  .intro-subtitle {
    margin: 0;
    font-size: 28px;
    text-align: center;
  }

  .intro-desc {
    font-size: 18px;
    text-align: center;
    max-width: unset;
    padding-bottom: 40px;
  }

  .intro-contact {
    font-size: 16px;
    align-self: center;
    margin-left: 30%;
  }
}

#experience {
  padding-left: 15%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.joblist-job-title {
  font-family: "NTR", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: var(--lightest-slate);
}

.joblist-job-company {
  font-family: "NTR", sans-serif;
  font-size: 28px;
  color: var(--green-bright);
  font-weight: bold;
}

.joblist-duration {
  font-family: "NTR", sans-serif;
  font-size: 18px;
  color: var(--slate);
}

#vertical-tabpanel {
  margin-top: -28px;
  padding-left: 25px;
}

.MuiTab-wrapper {
  font-size: 16px;
  text-align: left;
  align-items: flex-start !important;
  font-family: "NTR", sans-serif;
}

.MuiButtonBase-root {
  outline: none !important;
  color: var(--lightest-slate) !important;
}

.PrivateTabIndicator-colorSecondary-5 {
  background-color: var(--green-bright) !important;
}
.jss5 {
  background-color: var(--green-bright) !important;
}
.makeStyles-tabs-2 {
  border-right: 1px solid var(--lightest-navy) !important;
}
.MuiButtonBase-root:focus {
  color: var(--green-bright) !important;
}

ul {
  list-style: none;
  margin-left: -40px;
}

.job-description li::before {
  content: "\25B9      ";
  color: var(--green-bright);
  position: absolute;
  left: 0;
}

.job-description li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;
  font-size: 18px;
  color: var(--slate);
}

.job-description {
  padding-top: 24px;
  max-width: 650px;
  font-family: "NTR", sans-serif;
  font-size: 20px;
}

.job-description a,
.job-description b {
  font-size: 19px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  #experience {
    padding-left: unset;
    padding-right: unset;
    padding-top: 30%;
  }

  .jss1 {
    height: unset !important;
    flex-direction: column !important;
  }

  .makeStyles-root-1 {
    height: unset !important;
    width: unset;
    flex-direction: column;
  }

  .joblist-job-title {
    font-size: 22px;
  }

  .joblist-job-company {
    font-size: 22px;
  }
}

@font-face {
  font-family: "NTR";
  font-weight: 200;
  font-display: fallback;
  src: url("/fonts/NTR-Regular.ttf") format("truetype");
}

#about {
  padding-left: 15%;
  padding-top: 5%;
  padding-right: 25%;
}

.about-description ul {
  padding-top: 20px;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 10px));
}

.about-description li {
  position: relative;
  padding-left: 20px;
  font-size: 18px;
  color: var(--slate);
}

.about-description li::before {
  content: "\25B9      ";
  color: var(--green-bright);
  position: absolute;
  left: 0;
}

.about-content {
  display: flex;
  flex-direction: row;
}

.about-description {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  max-width: 700px;
  font-size: 20px;
  margin: 0;
}

.about-description a,
.about-description b {
  font-size: 19px;
  font-weight: bold;
}

.about-image {
  padding-left: 0px;
}

.about-image img {
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
  border: 2px solid var(--green-bright);
}

@media only screen and (max-width: 788px) {
  .about-image img {
    max-width: 200px;
  }
}

.about-image:hover {
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}

@media only screen and (max-width: 600px) {
  #about {
    height: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: 30%;
  }
  .about-image img {
    display: none;
  }

  .about-content {
    display: unset;
  }
}

#projects {
  padding-left: 15%;
  padding-top: 5%;
  padding-right: 25%;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-gap: 10px;
}
@media (max-width: 1080px) {
  .projects-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.projects-card {
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  position: relative;
  height: 100%;
  padding: 2rem 1.75rem;
  background-color: var(--light-navy);
  border-radius: 16px;
}

.projects-card:hover {
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
  background-color: var(--lightest-navy);
}

.card-header {
  margin-top: -20px;
  display: flex;
  padding: 1.25em 0 !important;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: none !important;
}
.folder-icon {
  color: var(--green-bright);
}

.github-icon {
  margin-top: 6px;
}
.github-icon:hover {
  color: var(--green-bright);
}

.open-icon {
  margin-left: 10px;
}

.card-title {
  font-family: "NTR", sans-serif;
  color: var(--lightest-slate);
  font-size: 24px;
  font-weight: bold;
}

.card-desc {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-size: 18px;
  padding-bottom: 40px;
}

.card-tech {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  #projects {
    height: unset;
    padding-top: 30%;
    padding-left: unset;
    padding-right: unset;
  }
}

#credits {
  display: flex;
  align-items: center;
  padding-top: 100px;
}

.ending-credits {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  text-align: center;
  width: 100%;
  font-size: 16px;
}

@font-face {
  font-family: "NTR";
  font-weight: 200;
  font-display: fallback;
  src: url("/fonts/NTR-Regular.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}

/* Animation */

.fade-in-section {
  opacity: 0;
  -webkit-transform: translateY(10vh);
          transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, -webkit-transform 1s ease-out;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  transition: opacity 0.6s ease-out, transform 1s ease-out, -webkit-transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  visibility: visible;
}

.App {
  background-color: var(--navy);
  display: flex;
  flex-direction: row;
  /* padding: 36px 76px; */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#content {
  /* padding: 80px 200px; */
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
}
.rs-steps-horizontal .rs-steps-item-content {
  text-align: left;
}

::selection {
  background: var(--lightest-navy); /* WebKit/Blink Browsers */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.section-header {
  display: flex;
  padding-bottom: 42px;
  width: 100vw;
}

.section-header::after {
  content: "";
  display: block;
  width: 300px;
  margin-top: 39px;
  height: 1px;
  margin-left: 20px;
  background-color: var(--lightest-navy);
}

.section-title {
  font-family: "NTR", sans-serif;
  color: var(--lightest-slate);
  font-size: 46px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .App {
    flex-direction: column;
    padding: 40px 24px;
  }

  #content {
    padding: 10px 10px;
    width: 100%;
  }

  .section-header {
    width: unset;
  }

  .section-header::after {
    display: none;
  }

  .section-title {
    font-size: 32px;
  }
}

:root {
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green-bright: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);
}

